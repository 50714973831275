#main{
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
}

#body{
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
}