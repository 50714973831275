@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
*{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

html, body, #root{
    height: 100%;
}

body{
    background: #E5E6F0;
    -webkit-font-smoothing: antialiased;
}

body, input, button{
    font-family: Roboto, sans-serif;
}
#app{
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
header{
    width: 100%;
    height: 60px;
    background: #12638f;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.02);
}
aside#sidebar{
    width: 280px;
    height: 100%;
    background: #32323a;
}

aside#sidebar ul{
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

aside#sidebar ul li{
    border-bottom: 1px solid #8c8c8c; 
}

aside#sidebar ul li a{
    display: block;
    padding: 14px;
    color: #adadb0;
    font-size: 14px;
    text-decoration:none;
    font-weight: bold;
}

aside#sidebar ul li a:hover{
    color: #fff;
    font-size: 16px;
    background: #262629;
}
aside#sidebar ul li a span{
    padding-left: 5px;
}


@media only screen and (max-width: 1000px){
    aside#sidebar{
        display: none;
    }
}
#thumbnail{
    margin-bottom: 20px;
    border: 1px dashed #ddd;
    background-size: cover;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    background-color: #fff;
    width: 50%;
}

#thumbnail input{
    display: none;
}
#loading{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
}

#loading img{
    width: 200px;
}


#thumbnail{
    margin-bottom: 20px;
    border: 1px dashed #ddd;
    background-size: cover;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    background-color: #fff;
    width: 50%;
}

#thumbnail input{
    display: none;
}
main{
    display: flex;
    flex: 1 1;
    margin: 15px 15px 0px 15px;
    box-shadow: 14px 14px 14px 14px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
}

.container{
    display: flex;
    flex: 1 1;
    flex-direction: column;
}

.container strong{
    font-size: 30px;
    text-align: flex-start;
    display: block;
    color: #333;
    padding: 15px;
}

.container .header{
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #ccc;
}

.container .header button{
    border: 0;
    background: #7D40E7;
    border-radius: 2px;
    padding: 10px 15px;
    font-weight: bold;
    font-size: 16px;
    color: #FFF;
    cursor: pointer;
    -webkit-transition: background 0.5s;
    transition: background 0.5s;
}

.container .header button:hover{
    background: #6931CA;
}

.container .painel{
    flex: 1 1;
}

.options{
    display: flex;
    justify-content: center;
}

form{
    margin-top: 30px;
}


form .input-block{
    margin-top: 20px;
}

form .input-group{
    margin-top: 20px;
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
}

form .input-group .input-block{
    margin-top: 0;
}

form .input-block label{
    color: #acacac;
    font-size: 14px;
    font-weight: bold;
    display: block;
}

form .input-block input{
    width: 100%;
    height: 32px;
    font-size: 14px;
    color: #666;
    border: 0;
    border-bottom: 1px solid #eee;  
}

form .input-block select{
    width: 100%;
    height: 32px;
    font-size: 14px;
    color: #666;
    border: 0;
    border-bottom: 1px solid #eee;  
}

form .input-block input[type=file]{
    width: 50%;
}

form .input-block textarea{
    width: 100%;
    font-size: 14px;
    color: #666;
    border: 0;
    border-bottom: 1px solid #eee;  
    resize: none;
    padding: 5px;
}

form button[type=submit]{
    width: 100%;
    border: 0;
    margin-top: 30px;
    background: #7d40e7;
    border-radius: 2px;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    -webkit-transform: background 0.5s;
            transform: background 0.5s; 
}

form button[type=submit]:hover{
    background: #6931ca;
}

table{
    width: 100%;
    border-collapse: collapse;
}

table tr{
    border-bottom: 1px solid #ccc;
}

table th{
    padding: 5px 15px;
}

table td{
    padding: 5px 15px;
    border-right: 1px solid #ccc;
}
table td:last-child{
    border-right: 0;
}

table td a{
    padding: 0px 5px;
}
#main{
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
}

#body{
    display: flex;
    flex-direction: row;
    flex: 1 1;
    width: 100%;
}
